
import { defineComponent } from "vue";
import NavbarComponent from "./components/NavbarComponent.vue";

export default defineComponent({
  name: "App",

  data() {
    return {
      //
    };
  },
  components: {
    NavbarComponent,
  },
});
