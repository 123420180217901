
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "NavbarComponent",
  methods: {
    logout() {
      localStorage.removeItem("token");
    },
  },
  computed: {
    isAuthenticated() {
      return localStorage.getItem("token") !== null;
    },
  },
});
