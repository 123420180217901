import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "content" }
const _hoisted_2 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_navbar_component = _resolveComponent("navbar-component")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_v_img, {
        src: require('./assets/drg_logo.png'),
        class: "m-0",
        contain: "",
        height: "200"
      }, null, 8, ["src"])
    ]),
    (_openBlock(), _createBlock(_component_navbar_component, {
      key: _ctx.$route.fullPath
    })),
    _createVNode(_component_router_view)
  ]))
}