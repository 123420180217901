
import { defineComponent } from "vue";
import ButtonComponent from "./reusable/ButtonComponent.vue";
import ROUTES from "../services/routes";
import { POSITION, useToast } from "vue-toastification";

export default defineComponent({
  data: () => {
    return {
      show: false,
      email: "",
      motdepasse: "",
      nom: "",
      niveau: 1,
      erreur: undefined as string | undefined,
    };
  },
  setup() {
    const toast = useToast();
    const options: any = {
      position: POSITION.TOP_RIGHT,
      timeout: 3016,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 1,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
      toastClassName: "my-custom-toast-class",
    };
    return { toast, options };
  },
  methods: {
    signup() {
      var regex = /\S+@\S+\.\S+/;
      if (this.email.length === 0) {
        this.erreur = "Veuillez entrer un courriel";
        return;
      }
      if (!this.email.match(regex)) {
        this.erreur = "Veuillez entrer un courriel valide";
        return;
      }
      if (this.motdepasse.length === 0) {
        this.erreur = "Veuillez entrer un mot de passe";
        return;
      }
      if (this.nom.length === 0) {
        this.erreur = "Veuillez entrer un nom d'utilisateur";
        return;
      }
      fetch(ROUTES.REGISTER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          nom: this.nom,
          motdepasse: this.motdepasse,
          niveau: this.niveau,
        }),
      }).then((response) => {
        if (response.status === 201) {
          this.toast.success("Votre compte a été créé !", this.options);
          this.$router.push("/login");
          return;
        } else if (response.status === 400) {
          this.erreur = "Ce courriel est déjà pris!";
        } else {
          this.toast.error("Il y a eu une erreur du serveur", this.options);
        }
      });
    },
  },
  components: {
    ButtonComponent,
  },
});
