import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-no-wrap" }
const _hoisted_2 = { class: "d-flex flex-column flex-fill" }
const _hoisted_3 = { class: "h3 mb-0 pb-0" }
const _hoisted_4 = { class: "h6 p-0 m-0 text-grey" }
const _hoisted_5 = { class: "h5" }
const _hoisted_6 = { class: "h5" }
const _hoisted_7 = { class: "h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mx-auto" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_avatar, {
          class: "p-0",
          size: "300",
          rounded: "0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_img, {
              class: "w-100 h-100",
              cover: "",
              src: require(`@/assets/planets/${_ctx.src}`)
            }, null, 8, ["src"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_card_title, { class: "d-flex flex-column align-items-start pb-0" }, {
            default: _withCtx(() => [
              _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.title), 1),
              _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.expiration), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider, {
            color: "primary",
            class: "mt-1 mb-0"
          }),
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, { class: "d-flex flex-column ps-2 pt-1 p-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "my-1" }, {
                    default: _withCtx(() => [
                      _createElementVNode("small", _hoisted_5, [
                        _createVNode(_component_v_icon, {
                          icon: "mdi-cash-multiple",
                          class: "mx-1"
                        }),
                        _createTextVNode(_toDisplayString(_ctx.prime), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, { class: "my-1" }, {
                    default: _withCtx(() => [
                      _createElementVNode("small", _hoisted_6, [
                        _createVNode(_component_v_icon, {
                          icon: "mdi-diamond-stone",
                          class: "mx-1"
                        }),
                        _createTextVNode(_toDisplayString(_ctx.ressource), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, { class: "my-1" }, {
                    default: _withCtx(() => [
                      _createElementVNode("small", _hoisted_7, [
                        _createVNode(_component_v_icon, {
                          icon: "mdi-weight",
                          class: "mx-1"
                        }),
                        _createTextVNode(_toDisplayString(_ctx.qte), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, { class: "my-1" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.danger, (i) => {
                        return (_openBlock(), _createBlock(_component_v_icon, {
                          key: i,
                          icon: "mdi-alert-octagon",
                          color: "#b01708",
                          class: "mx-1",
                          size: "30"
                        }))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "w-100" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                rounded: "lg",
                "prepend-icon": _ctx.clicked ? 'mdi-note-check' : 'mdi-note-plus-outline',
                color: "primary",
                class: "align-self-end",
                disabled: _ctx.clicked
              }, {
                default: _withCtx(() => [
                  (!_ctx.clicked)
                    ? (_openBlock(), _createElementBlock("small", {
                        key: 0,
                        onMouseup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reserver && _ctx.reserver(...args))),
                        class: "label"
                      }, "Réserver", 32))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["prepend-icon", "disabled"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}