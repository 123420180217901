import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "my-2 border-bottom" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        onClick: _ctx.toggle,
        class: "title"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_icon, {
            end: "",
            icon: _ctx.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_ctx.isOpen)
        ? _renderSlot(_ctx.$slots, "default", {
            key: 0,
            class: "mt-2"
          })
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}