
import { defineComponent } from "vue";
import ExpansionPanel from "./reusable/ExpansionPanel.vue";
import ContratCard from "./reusable/ContratCard.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { getContrats } from "../services/requests";
import ButtonComponent from "./reusable/ButtonComponent.vue";

export default defineComponent({
  name: "SearchComponent",
  data: () => ({
    contrats: [] as Array<any>,
    minPrime: "",
    maxPrime: "",
    dates: [] as Array<Date>,
    dangers: [] as Array<number>,
    chips: [] as Array<string>,
    planets: [] as Array<string>,
  }),
  async created() {
    await this.loadContrats();
    this.planets = this.contrats
      .map((contrat: any) => contrat.planeteNom)
      .filter((value, index, self) => self.indexOf(value) === index);
  },
  components: {
    ExpansionPanel,
    Datepicker,
    ContratCard,
    ButtonComponent,
  },
  watch: {
    dates() {
      this.loadContrats();
    },
    dangers() {
      this.loadContrats();
    },
  },
  methods: {
    async loadContrats() {
      this.contrats = await getContrats(
        this.dates && this.dates.length
          ? new Date(this.dates[0]).toISOString()
          : undefined,
        this.dates && this.dates.length
          ? new Date(this.dates[1]).toISOString()
          : undefined,
        this.minPrime,
        this.maxPrime,
        this.dangers,
        this.chips
      );
    },
    isNumber(event: KeyboardEvent, value: string) {
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || value.indexOf(".") !== -1)
      ) {
        event.preventDefault();
        return false;
      }
      return true;
    },
    remove(item: any) {
      this.chips.splice(this.chips.indexOf(item as never), 1);
      this.chips = [...this.chips];
    },
  },
});
