
import { defineComponent } from "vue";
import { getReservations, getContrat, getPlanete } from "../services/requests";

export default defineComponent({
  name: "SearchComponent",
  data: () => ({
    contrats: [] as any[],
    reservations: [] as Array<any>,
    primeTotale: 0,
    mineurId: String,
  }),
  async created() {
    this.reservations = await getReservations(localStorage.getItem("token"));
    this.loadContrats();
  },
  methods: {
    async loadContrats() {
      if (this.reservations && this.reservations.length > 0)
        for (const reservation of this.reservations) {
          const contrat = await getContrat(
            reservation.relationships.contrat.links.related
          );
          this.primeTotale += contrat.attributes.prime;
          const planete = (await getPlanete(
            contrat.relationships.planete.links.related
          )) as any;
          contrat.attributes.image = planete.image;
          contrat.attributes.nom = planete.nom;
          this.contrats.push(contrat.attributes);
        }
    },
  },
});
