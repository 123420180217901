
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExpansionPanel",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isOpen: true,
  }),
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
});
