
import { reserverContrat } from "@/services/requests";
import moment from "moment";
import { Component, defineComponent } from "vue";
import { POSITION, useToast } from "vue-toastification";
import LinkComponent from "./LinkComponent.vue";

export default defineComponent({
  name: "ContratCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    prime: {
      type: Number,
      required: true,
    },
    danger: {
      type: Number,
      required: true,
    },
    ressource: {
      type: String,
      required: true,
    },
    qte: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    clicked: false,
  }),
  setup: () => {
    const toast = useToast();
    return { toast };
  },
  computed: {
    expiration() {
      return moment(this.date).format("DD/MM/YYYY");
    },
  },
  methods: {
    async reserver() {
      const res = await reserverContrat(
        this.url,
        localStorage.getItem("token")
      );

      if (res.status === 201) {
        this.message("Reservation effectuée", "success");
        this.clicked = true;
      } else if (res.status === 409) {
        this.message("Vous avez déjà réservé ce contrat", "info");
        this.clicked = true;
      } else if (res.status === 401) {
        this.message(LinkComponent, "error", {
          click: () => this.$router.push({ name: "login" }),
        });
      } else {
        this.message("Une erreur est survenue", "error");
      }
    },
    message(slot: string | Component, type = "", listener?: any) {
      const options: any = {
        position: POSITION.TOP_RIGHT,
        timeout: 3016,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
        toastClassName: "my-custom-toast-class",
      };

      switch (type) {
        case "success":
          this.toast.success(slot, options);
          break;
        case "error":
          if (listener) {
            this.toast.error(
              {
                component: slot,
                listeners: listener,
              },
              options
            );
          } else {
            this.toast.error(slot, options);
          }
          break;
        default:
          this.toast(slot, options);
          break;
      }
    },
  },
});
