
import { defineComponent } from "vue";
import ROUTES from "../services/routes";
import ButtonComponent from "./reusable/ButtonComponent.vue";
import { POSITION, useToast } from "vue-toastification";

export default defineComponent({
  data: () => {
    return {
      show: false,
      email: "",
      motdepasse: "",
      erreur: undefined as string | undefined,
    };
  },
  setup: () => {
    const toast = useToast();
    const options: any = {
      position: POSITION.TOP_RIGHT,
      timeout: 3016,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 1,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
      toastClassName: "my-custom-toast-class",
    };
    return { toast, options };
  },
  created() {
    if (this.$route.query.redirect)
      this.toast.error(
        "Vous devez être connecté pour accéder à cette page",
        this.options
      );
  },
  methods: {
    login() {
      fetch(ROUTES.LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          motdepasse: this.motdepasse,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            this.erreur = "Email ou mot de passe incorrect";
          } else {
            this.toast.error(
              "Une erreur est survenue, veuillez réessayer plus tard",
              this.options
            );
          }
        })
        .then((data) => {
          localStorage.setItem("token", data.token);
          this.$router.push("/");
          this.$forceUpdate();
        });
    },
  },
  components: {
    ButtonComponent,
  },
});
